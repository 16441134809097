import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useMemo,
} from "react";

interface RadioButtonGroupProps<T>
  extends Omit<ButtonGroupProps, "onChange" | "variant" | "isAttached"> {
  name?: string;
  value?: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
}

export const RadioButtonGroup = <T extends string>(
  props: RadioButtonGroupProps<T>,
) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
    isDisabled: props.isDisabled,
  });

  const buttons = useMemo(() => {
    const validChildren =
      Children.toArray(children).filter<ReactElement<RadioButtonProps>>(
        isValidElement,
      );

    return validChildren.map((button, index, array) => {
      const radio = getRadioProps({
        value: button.props.value,
        disabled: props.isDisabled || button.props.isDisabled,
      });

      const position =
        index === 0 ? "first" : index === array.length - 1 ? "last" : "middle";

      const isNextChecked = array[index + 1]?.props?.value === value;
      const isPreviousChecked = array[index - 1]?.props?.value === value;

      const styleProps = Object.assign({
        ...(position === "first" ? { borderRightRadius: 0 } : {}),
        ...(position === "last" ? { borderLeftRadius: 0 } : {}),
        ...(position === "middle" ? { borderRadius: 0 } : {}),
        ...(position === "middle"
          ? {
              borderLeftWidth: radio.isChecked ? "1px" : 0,
              borderRightWidth: radio.isChecked ? "1px" : 0,
            }
          : {}),
        ...(position === "first"
          ? { borderRightWidth: isNextChecked ? 0 : "1px" }
          : {}),

        ...(position === "last"
          ? { borderLeftWidth: isPreviousChecked ? 0 : "1px" }
          : {}),
      });

      return cloneElement(button, {
        ...styleProps,
        radioProps: getRadioProps({
          value: button.props.value,
          isDisabled: props.isDisabled || button.props.isDisabled,
        }),
      });
    });
  }, [children, getRadioProps, props.isDisabled, value]);
  return (
    <ButtonGroup isAttached variant="secondary" {...getRootProps(rest)}>
      {buttons}
    </ButtonGroup>
  );
};

interface RadioButtonProps extends ButtonProps {
  value: string;
  radioProps?: UseRadioProps;
}

export const RadioButton = ({ radioProps, ...rest }: RadioButtonProps) => {
  const { getInputProps, getRadioProps, getLabelProps } = useRadio(radioProps);
  const id = useId(undefined, "radio-button");

  const inputProps = getInputProps();

  return (
    <Box
      as="label"
      cursor="pointer"
      {...getLabelProps()}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      <input {...inputProps} aria-labelledby={id} />
      <Button
        variant="outline"
        colorScheme={inputProps.checked ? "blue" : "gray"}
        id={id}
        as="div"
        _focus={{ boxShadow: "none" }}
        _disabled={{ opacity: 0.8, cursor: "not-allowed" }}
        {...getRadioProps()}
        {...rest}
      />
    </Box>
  );
};
