import type { PaginationState } from "@tanstack/react-table";
import { useMemo } from "react";

import { getOnPaginationChange, useSearchParams } from "@stordco/fe-components";

import type { PaginationMetadata } from "../store/services/api.generated";

export const useTanstackPagination = (metadata?: PaginationMetadata) => {
  const [params] = useSearchParams();

  const page = metadata?.page_number
    ? metadata.page_number
    : params.page
      ? Number(params.page)
      : 1;

  return useMemo((): {
    pageCount: number;
    pagination: PaginationState;
  } => {
    const pageSize = metadata?.page_size ?? 10;

    if (!metadata) {
      return { pageCount: -1, pagination: { pageSize, pageIndex: -1 } };
    }

    const pageCount = metadata.total_pages ?? 1;
    const pageIndex = page - 1;

    return {
      pageCount,
      pagination: {
        pageSize,
        pageIndex,
      },
    };
  }, [metadata, page]);
};

export const useTanstackPaginationChange = (metadata?: PaginationMetadata) => {
  const [params, { append, remove }] = useSearchParams();

  const page = metadata?.page_number
    ? metadata.page_number
    : params.page
      ? Number(params.page)
      : 1;

  const pageIndex = page - 1;
  const pageSize = metadata?.page_size ?? 10;

  return {
    onPaginationChange: getOnPaginationChange({
      pagination: { pageSize, pageIndex },
      onPrevious: () => {
        if (page > 1) {
          append("page", page - 1);
        } else {
          remove("page");
        }
      },
      onNext: () => {
        append("page", page + 1);
      },
    }),
  };
};
