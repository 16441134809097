import { useMemo, useCallback } from "react";
import qs from "query-string";
import type { StringifyOptions } from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

type Values =
  | string
  | Array<string | null>
  | number
  | number[]
  | boolean
  | null
  | undefined;

type Append = {
  (key: string, value: Values): void;
  (values: Record<string, Values>): void;
};

type Remove = (key: string | string[]) => void;

type ReplaceAll = {
  (key: string, value: Values): void;
  (values: Record<string, Values>): void;
};

type Clear = () => void;

type Stringify = (options?: {
  options?: StringifyOptions;
  extraParams?: Record<string, any>;
}) => string;

/**
 * Returns an object containing the current page's search params and a set of actions
 * to modify those params.
 *
 * @remarks If you have state that you need to pass along with a query that doesn't exist in the search params,
 * you can pass it in as the `extraParams` parameter of `stringify`
 *
 * @example ```
 * const [params, { append, remove, replaceAll, clear, stringify }] = useSearchParams()
 * const someQuery = fetch(`http://example.com/${stringify({ extraParams: { foo: "bar" } })}`)
 * ```
 */
export const useSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentSearch = useMemo(() => {
    return qs.parse(location.search);
  }, [location.search]);

  const append: Append = useCallback(
    (keyOrValues: string | Record<string, Values>, value?: Values) => {
      let newSearchParams;

      if (typeof keyOrValues === "object") {
        newSearchParams = {
          ...currentSearch,
          ...keyOrValues,
        };
      } else {
        newSearchParams = {
          ...currentSearch,
          [keyOrValues]: value,
        };
      }

      navigate({
        hash: location.hash,
        search: qs.stringify(newSearchParams),
      });
    },
    [currentSearch, navigate, location.hash],
  );

  /**
   * Removes a single param or multiple params
   * @param key string | string[]
   */
  const remove: Remove = useCallback(
    (key) => {
      const newSearchParams = { ...currentSearch };

      if (Array.isArray(key)) {
        key.forEach((entry) => delete newSearchParams[entry]);
      } else {
        delete newSearchParams[key];
      }

      navigate({
        hash: location.hash,
        search: qs.stringify(newSearchParams),
      });
    },
    [currentSearch, navigate, location.hash],
  );

  /**
   * Replaces current search params with new params
   * @param key the key to replace or a new search param object
   * @param value the key's new value
   */
  const replaceAll: ReplaceAll = useCallback(
    (keyOrValues: string | Record<string, Values>, value?: Values) => {
      let newSearchParams;

      if (typeof keyOrValues === "object") {
        newSearchParams = keyOrValues;
      } else {
        newSearchParams = {
          [keyOrValues]: value,
        };
      }

      return navigate({
        hash: location.hash,
        search: qs.stringify(newSearchParams),
      });
    },
    [navigate, location.hash],
  );

  /**
   * Clears all search params
   */
  const clear: Clear = useCallback(() => navigate({ search: "" }), [navigate]);

  /**
   * Converts the current search params into a string
   * Additionally, you can pass an object of extra params to add to the string that live outside of the url state
   */
  const stringify: Stringify = useCallback(
    ({ options, extraParams = {} } = {}) =>
      qs.stringify({ ...currentSearch, ...extraParams }, options),
    [currentSearch],
  );

  return [
    currentSearch,
    {
      append,
      remove,
      replaceAll,
      clear,
      stringify,
    },
  ] as const;
};
