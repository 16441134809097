import { useMemo } from "react";

import type { FilterProps } from "../components";

import { useSearchParams } from ".";

export type BaseFilterOption = {
  label: string;
  value: string;
};

export interface UseBaseFilterArg<
  Option extends BaseFilterOption = BaseFilterOption,
> {
  options: Option[];
  scope: string;
  queryParam: string;
  isMulti?: boolean;
  onSelect?: (selection: string[] | string) => void;
  onClear?: () => void;
}

export const useBaseFilter = <Option extends BaseFilterOption>({
  options: optionsByFilter = [] as Option[],
  scope: baseScope,
  queryParam,
  isMulti,
  onSelect: onSelectProp,
  onClear: onClearProp,
}: UseBaseFilterArg<Option>): FilterProps => {
  const [params, { replaceAll }] = useSearchParams();

  const filterScope = useMemo(() => {
    if (!optionsByFilter.length) {
      return baseScope;
    }
    const filterValue = params[queryParam] || [];

    const filteredOptions = optionsByFilter.filter(({ value }) => {
      if (Array.isArray(filterValue)) {
        return filterValue.includes(value);
      }
      return filterValue === value;
    });

    return filteredOptions.length === 1
      ? `${baseScope}: ${filteredOptions[0].label}`
      : filteredOptions.length > 1
        ? `${baseScope}: ${filteredOptions.length} selected`
        : baseScope;
  }, [optionsByFilter, params, queryParam, baseScope]);

  const options = useMemo(() => {
    const currentVal = params[queryParam] || [];

    return optionsByFilter
      .filter((option) => option.label)
      .map((option) => {
        return {
          id: option.value,
          label: option.label ?? "",
          selected: currentVal === option.value,
        };
      });
  }, [optionsByFilter, params, queryParam]);

  const onSelect = (val: string[] | string) => {
    onSelectProp?.(val);

    replaceAll({
      ...params,
      after: undefined,
      before: undefined,
      [queryParam]: val,
    });
  };

  const onClear = () => {
    onClearProp?.();

    replaceAll({
      ...params,
      after: undefined,
      before: undefined,
      [queryParam]: undefined,
    });
  };

  return {
    onClear,
    onSelect,
    filterScope,
    options,
    ...(isMulti && { type: "checkbox" }),
  };
};
