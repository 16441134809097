import {
  chakra,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuList,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import type {
  ButtonGroupProps,
  MenuButtonProps,
  MenuOptionGroupProps,
} from "@chakra-ui/react";
import type { DateValue } from "@internationalized/date";
import type { DatePickerAria } from "@react-aria/datepicker";
import { useState, useRef, type UIEventHandler } from "react";
import {
  faAngleDown,
  faCheck,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";

import type { ToggleOption } from "../ButtonToggle";
import { ButtonToggle } from "../ButtonToggle";
import { DatePicker } from "../DatePicker";
import { FaIcon } from "../FaIcon";

export interface FilterOption {
  label: string;
  shortLabel?: string;
  id: string;
  selected?: boolean;
}

export interface FilterProps {
  filterScope: string;
  options?: FilterOption[];
  onSelect: MenuOptionGroupProps["onChange"];
  onToggle?: (selected: string) => void;
  onClear?: UIEventHandler<HTMLElement>;
  withSearch?: boolean;
  toggleOptions?: ToggleOption[];
  type?: "radio" | "checkbox";
  menuButtonOverrides?: MenuButtonProps;
  menuButtonGroupOverrides?: ButtonGroupProps;
}

export const Filter = ({
  filterScope,
  options = [],
  onSelect,
  onClear,
  withSearch = false,
  type = "radio",
  onToggle,
  toggleOptions,
  menuButtonOverrides = {},
  menuButtonGroupOverrides = {},
}: FilterProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const searchInputRef = useRef<HTMLInputElement>(null);

  const someSelected = options.some(({ selected }) => selected);

  return (
    <Menu
      closeOnSelect={type !== "checkbox"}
      initialFocusRef={searchInputRef as any}
    >
      <ButtonGroup
        size="sm"
        variant="outline"
        isAttached={someSelected}
        {...menuButtonGroupOverrides}
      >
        <MenuButton
          as={Button}
          maxWidth="300px"
          colorScheme="gray"
          rightIcon={
            !(onClear && someSelected) && <FaIcon icon={faAngleDown} />
          }
          {...menuButtonOverrides}
        >
          <chakra.span display="block" isTruncated>
            {filterScope}
          </chakra.span>
        </MenuButton>

        {onClear && someSelected && (
          <IconButton
            aria-label="Clear filters"
            borderLeft="0px"
            colorScheme="gray"
            onClick={onClear}
            icon={<FaIcon icon={faXmark} />}
          />
        )}
      </ButtonGroup>

      <MenuList fontSize="sm" zIndex="popover">
        {toggleOptions?.length && onToggle ? (
          <>
            <Box px={2}>
              <ButtonToggle
                options={toggleOptions}
                onChange={onToggle}
                size="xs"
              />
            </Box>
            <Divider mb={2} />
          </>
        ) : null}
        {withSearch && (
          <>
            <Box mx={2}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaIcon icon={faMagnifyingGlass} />
                </InputLeftElement>
                <Input
                  value={searchTerm}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  ref={searchInputRef}
                  placeholder="Search"
                  type="search"
                  bg="white"
                />
              </InputGroup>
            </Box>

            <Divider my={2} />
          </>
        )}
        <Box maxHeight="xs" overflowY="auto">
          <MenuOptionGroup
            type={type}
            onChange={onSelect}
            value={
              type === "radio"
                ? (options.find((option) => option.selected)?.id ?? "")
                : options.filter(({ selected }) => selected).map(({ id }) => id)
            }
          >
            {options
              .filter((item) =>
                item.label.toLowerCase().startsWith(searchTerm.toLowerCase()),
              )
              .map((option) => (
                <MenuItemOption
                  key={option.id}
                  value={option.id}
                  icon={<FaIcon icon={faCheck} />}
                >
                  {option.label}
                </MenuItemOption>
              ))}
          </MenuOptionGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};

export interface DateFilterProps {
  fromDateValue: DateValue | null;
  toDateValue: DateValue | null;
  fromDateOnChange: DatePickerAria["fieldProps"]["onChange"];
  toDateOnChange: DatePickerAria["fieldProps"]["onChange"];
  buttonLabel: string;
  onClear: () => void;
}

export const isDateFilter = (props: any): props is DateFilterProps =>
  "fromDateValue" in props && "toDateValue" in props;

export const DateFilter = ({
  fromDateValue,
  toDateValue,
  fromDateOnChange,
  toDateOnChange,
  buttonLabel,
  onClear,
}: DateFilterProps) => {
  const [shouldCloseOnBlur, setShouldCloseOnBlur] = useState(true);
  const hasValues = fromDateValue || toDateValue;

  return (
    <Popover
      placement="bottom-start"
      returnFocusOnClose={false}
      closeOnBlur={shouldCloseOnBlur}
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <ButtonGroup isAttached size="sm" variant="outline">
              <Button
                variant="outline"
                colorScheme="gray"
                rightIcon={
                  !hasValues ? <FaIcon icon={faAngleDown} /> : undefined
                }
                isActive={isOpen}
              >
                {buttonLabel}
              </Button>
              {onClear && hasValues && (
                <IconButton
                  aria-label="Clear filters"
                  borderLeft="0px"
                  colorScheme="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                  icon={<FaIcon icon={faXmark} />}
                />
              )}
            </ButtonGroup>
          </PopoverTrigger>
          <PopoverContent
            width="200px"
            _focus={{ outline: "none" }}
            border="none"
            boxShadow="md"
            borderRadius="base"
          >
            <PopoverBody p={4}>
              <Flex flexDirection="column" gap={4}>
                <FormControl>
                  <DatePicker
                    label="From"
                    value={fromDateValue}
                    onChange={fromDateOnChange}
                    maxValue={toDateValue ?? undefined}
                    setShouldCloseOnBlur={setShouldCloseOnBlur}
                  />
                </FormControl>
                <FormControl>
                  <DatePicker
                    label="To"
                    value={toDateValue}
                    onChange={toDateOnChange}
                    minValue={fromDateValue ?? undefined}
                    setShouldCloseOnBlur={setShouldCloseOnBlur}
                  />
                </FormControl>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
